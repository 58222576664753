import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/dashboard/Layout";
import ReservationLists from "./pages/dashboard/ReservationLists";
import UsersList from "./pages/dashboard/UsersList";
import AddUser from "./pages/dashboard/AddUser";
import AddService from "./pages/dashboard/AddService";
import ServicesList from "./pages/dashboard/ServicesList";
import useSettings from "./hooks/useSettings";
import { createCustomTheme } from "./theme";
import { ThemeProvider } from "@mui/material";
import Landing from "./pages/Landing";
import ErrorPage from "./pages/ErrorPage";
import GroupPage from "./pages/dashboard/GroupPage";
import AddSubService from "./pages/dashboard/AddSubService";
import ProtectedRoute from "./pages/ProtectedRoute";
import AddGroup from "./pages/dashboard/AddGroup";
import Account from "./pages/dashboard/Account";
import NoPermissionsPage from "./pages/NoPermissionsPage";
import ShiftsPage from "./pages/dashboard/ShiftsPage";
import AddShift from "./pages/dashboard/AddShift";
import SubServicesList from "./pages/dashboard/SubServicesList";
import CreateReservation from "./pages/dashboard/booking/CreateReservation";

function App() {
  const { settings } = useSettings();

  const theme = createCustomTheme({
    theme: settings?.franchise,
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route index element={<ReservationLists />} />
            <Route path='users' element={<UsersList />} />
            <Route path='groups' element={<GroupPage />} />
            <Route path='shifts' element={<ShiftsPage />} />
            <Route path='shifts/add-shift' element={<AddShift />} />
            <Route path='shifts/:id/edit' element={<AddShift />} />
            <Route path='profile' element={<Account />} />
            <Route path='groups/:id/edit' element={<AddGroup />} />
            <Route path='users/add-user' element={<AddUser />} />
            <Route path='users/:id/edit' element={<AddUser />} />
            {/*<Route path='services' element={<ServicesList />} />*/}
            {/*<Route path='services/add-service' element={<AddService />} />*/}
            {/*<Route path='services/:id/edit' element={<AddService />} />*/}
            {/*<Route path='subservices/:service' element={<SubServicesList />} />*/}
            {/*<Route*/}
            {/*  path='subservices/:service/add-subservice'*/}
            {/*  element={<AddSubService />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path='subservices/:service/:id/edit'*/}
            {/*  element={<AddSubService />}*/}
            {/*/>*/}

            <Route path='create-reservation' element={<CreateReservation />} />
            <Route path='view-reservation' element={<CreateReservation />} />
            <Route
              path='create-reservation/edit/:reservationIdentification'
              element={<CreateReservation />}
            />

            <Route
              path='create-reservation/edit/:reservationIdentification/:itemIdentification'
              element={<CreateReservation />}
            />
          </Route>
          <Route path='/login' element={<Landing />} />
          <Route path='*' element={<ErrorPage />} />
          <Route path='/405' element={<NoPermissionsPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
