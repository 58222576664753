import axios from "axios";
//carpediembooking.elatus.dev je devlopment/sandobox
//carpediem.elatus.dev je production -- Pareleni production sa novim

//NOVI PRODUCTION -> www.booking.cdhvar.com
//NOVI PRODUCTION ADMIN -> https://www.payments.cdhvar.com/admin/

export const isProd = true;

let URL;

// if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
//   URL = "/en";
// } else {
  if (isProd) {
    URL = process.env.REACT_APP_PRODUCTION_API;
  } else {
    URL = process.env.REACT_APP_SANDBOX_API;
  }
// }


export default axios.create({
  baseURL: URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

export const axiosPrivate = axios.create({
  baseURL: URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});
