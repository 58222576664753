
import {TableFiltersWrapper} from "./styles";
import {
    Box,
    FormControl,
   InputLabel, Select, MenuItem
} from "@mui/material";

const ReservationTableFiltersExtra = ({queryValues,handleQueryChange}) => {

    return (
        <TableFiltersWrapper>

            <div className="inner-wrapper inner-wrapper-alt">
            <Box sx={{ minWidth: 120 }} className="arrivedSelect">
                <FormControl fullWidth>
                    <InputLabel id="arrived">Arrived</InputLabel>
                    <Select
                        labelId="arrived"
                        name="arrived"
                        value={queryValues.arrived}
                        label="Arrived"
                        onChange={handleQueryChange}
                    >
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={"1"}>Arrived</MenuItem>
                        <MenuItem value={"2"}>Not Arrived</MenuItem>

                    </Select>
                </FormControl>
            </Box>
                <Box sx={{ minWidth: 120 }} className="approvedSelect">
                    <FormControl fullWidth>
                        <InputLabel id="approved">Approved</InputLabel>
                        <Select
                            labelId="approved"
                            name="approved"
                            value={queryValues.approved}
                            label="Approved"
                            onChange={handleQueryChange}
                        >
                            <MenuItem value={"All"}>All</MenuItem>
                            <MenuItem value={"1"}>Approved</MenuItem>
                            <MenuItem value={"0"}>Not Approved</MenuItem>

                        </Select>
                    </FormControl>

                </Box>
            <Box sx={{ minWidth: 120 }} className="sortBySelect">
                <FormControl fullWidth>
                    <InputLabel id="sort">Sort By</InputLabel>
                    <Select
                        labelId="sort"
                        name="sort"
                        value={queryValues.sort}
                        label="Sort By"
                        onChange={handleQueryChange}
                    >
                        <MenuItem value={"1"}>ID DESC</MenuItem>
                        <MenuItem value={"2"}>ID ASC</MenuItem>
                        <MenuItem value={"3"}>Datum DESC</MenuItem>
                        <MenuItem value={"4"}>Datum ASC</MenuItem>
                    </Select>
                </FormControl>
            </Box>

                <Box sx={{ minWidth: 120 }} className="paidSelect">
                    <FormControl fullWidth>
                        <InputLabel id="paid">Paid</InputLabel>
                        <Select
                            labelId="paid"
                            name="paid"
                            value={queryValues.paid}
                            label="Paid"
                            onChange={handleQueryChange}
                        >
                            <MenuItem value={"All"}>All</MenuItem>
                            <MenuItem value={"0"}>Not Paid</MenuItem>
                            <MenuItem value={"1"}>Paid</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ minWidth: 120 }} className="sourceSelect">
                    <FormControl fullWidth>
                        <InputLabel id="source">Source</InputLabel>
                        <Select
                            labelId="source"
                            name="source"
                            value={queryValues.source}
                            label="Source"
                            onChange={handleQueryChange}
                        >
                            <MenuItem value={"All"}>All</MenuItem>
                            <MenuItem value={"1"}>Booking App</MenuItem>
                            <MenuItem value={"2"}>Web Booking</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

            </div>

        </TableFiltersWrapper>
    );
};

export default ReservationTableFiltersExtra;
