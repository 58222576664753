import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SendIcon from '@mui/icons-material/Send';
import {Card, FormControlLabel, Grid, IconButton, Stack, Switch, Typography} from "@mui/material";
import Tooltip from '@material-ui/core/Tooltip';
import {useState} from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {Form, FormikProvider, useFormik} from "formik";
import {MIconButton} from "../../helpers/@material-extend";
import {Icon} from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {MailModalWrapper, ModalListWrapper} from "./styles";


export default function SingleReservationMailModal({order}) {
    const axiosPrivate = useAxiosPrivate();
    const [openMailModal, setOpenMailModal] = useState(false);
    const [orderDetails, setOrderDetails] = useState({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleClickOpen = () => {
        setOpenMailModal(true);
        getOrder()
    };

    const handleClose = () => {
        setOpenMailModal(false);
    };

    const getOrder = async () => {
        try {
            const response = await axiosPrivate(`/order/${order}`);

            if (response.data.code === 200 ) {
                setOrderDetails(response.data.data);

            } else {
                setOrderDetails({});
            }

        } catch (error) {
            console.log(error);
        }
    };
    const MailSchema = Yup.object().shape({
        email: Yup.string().email().required("Email is required"),
        subject: Yup.string().required("Subject is required"),

    });


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: orderDetails?.id,
            email:orderDetails?.email || "",
            subject:"",
            message:"",
            paymentUrl:0
        },
        validationSchema: MailSchema ,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            const createConvertedValues = {
                email: values.email,
                subject: values.subject,
                message: values.message,
                paymentUrl: values.paymentUrl,
            };
            try {
                const response = await axiosPrivate.post(
                    `/order/send/${orderDetails?.id}`,
                    createConvertedValues
                );

                if (response?.data.code === 200) {
                    resetForm();
                    setSubmitting(false);


                    enqueueSnackbar(
                        "Email sent successfully",

                        {
                            variant: "success",
                            action: (key) => (
                                <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                                    <Icon icon={closeFill} />
                                </MIconButton>
                            ),
                        }
                    );

                    handleClose();
                }
            } catch (error) {
                console.error(error);
                setSubmitting(false);
                setErrors(error);

            }
        },
    });
    const {
        errors,
        values,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        getFieldProps,
    } = formik;

    return (
        <div>
            <IconButton onClick={handleClickOpen} >
                <SendIcon />
            </IconButton>
            <Dialog open={openMailModal} onClose={handleClose}>
                {Object.keys(orderDetails).length > 0 ?
                    <MailModalWrapper>

                <DialogTitle>Info Email</DialogTitle>
                <DialogContent>

                    <DialogContentText sx={{marginBottom:4}}>
                        Create order on the website!
                    </DialogContentText>

                    <FormikProvider value={formik}>
                        <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Card sx={{ p: 3}} className={"smallForms"}>
                                        <Stack spacing={3}>
                                            <Tooltip title="To enable this form please add email to this order!">
                                                <div>
                                                    <TextField
                                                        fullWidth
                                                        label='Email'
                                                        {...getFieldProps("email")}
                                                        error={Boolean(touched.email && errors.email)}
                                                        helperText={touched.email && errors.email}
                                                        inputProps={{
                                                            readOnly: true
                                                        }}
                                                        sx={{
                                                            opacity: 0.5,
                                                            pointerEvents:"none"
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>

                                            <TextField
                                                fullWidth
                                                label='Subject'
                                                type="subject"
                                                {...getFieldProps("subject")}
                                                error={Boolean(touched.subject && errors.subject)}
                                                helperText={touched.subject && errors.subject}
                                            />
                                            <TextField

                                                fullWidth
                                                multiline
                                                minRows={4}
                                                maxRows={4}
                                                label='Description'
                                                {...getFieldProps("message")}
                                                error={Boolean(touched.message && errors.message)}
                                                helperText={touched.message && errors.message}
                                            />


                                            <Box
                                                sx={{
                                                    mt: 3,
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Card sx={{ p: 1, minWidth: "150px" }}>
                                                <FormControlLabel
                                                    labelPlacement='start'
                                                    control={
                                                        <Switch
                                                            onChange={(event) =>
                                                                setFieldValue(
                                                                    "paymentUrl",
                                                                    event.target.checked ? 1 : 0
                                                                )
                                                            }
                                                            checked={values.paymentUrl === 1}
                                                        />
                                                    }
                                                    label={
                                                        <>
                                                            <Typography variant='subtitle2'>Include payment URL</Typography>
                                                        </>
                                                    }
                                                    sx={{
                                                        mx: 0,

                                                        width: 1,
                                                        justifyContent: "space-between",
                                                    }}
                                                />
                                                </Card>
                                                <LoadingButton
                                                    type='submit'
                                                    variant='contained'
                                                    loading={isSubmitting}
                                                >
                                                    Send Email
                                                </LoadingButton>
                                            </Box>
                                        </Stack>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                        </DialogActions>
            </MailModalWrapper>
                :

                <Box sx={{ display: 'flex', alignItems:"center", justifyContent:"center", minWidth:"100%", minHeight:"250px;" }}>
                    <CircularProgress />
                </Box>
                }


            </Dialog>
        </div>
    );
}