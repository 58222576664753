import * as Yup from "yup";
import {  useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { RiLockPasswordFill } from "react-icons/ri";
// material
import {} from "@mui/material";
// material
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  IconButton,
  Button,
  InputAdornment,
} from "@mui/material";

import { MIconButton } from "../../helpers/@material-extend";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useAuthContext } from "../../../context/auth/authContext";
// utils

// routes

// ----------------------------------------------------------------------

export default function AccountForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const { addStateUser, user } = useAuthContext();
  const countries = [
    { code: "AD", label: "Andorra", phone: "376" },
    { code: "AE", label: "United Arab Emirates", phone: "971" },
    { code: "AF", label: "Afghanistan", phone: "93" },
    { code: "AG", label: "Antigua and Barbuda", phone: "1-268" },
    { code: "AI", label: "Anguilla", phone: "1-264" },
  ];
  const NewUserSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    username: Yup.string().required("Username is required").email(),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),

    password2: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      username: user?.username || "",
      password: "",
      password2: "",
      status: user?.status || "active",
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        const response = await axiosPrivate.post("/user", values);

        if (response?.data.code === 200) {
          resetForm();
          addStateUser(response.data.data);
          setSubmitting(false);
          enqueueSnackbar("Account updated successuflly", {
            variant: "success",
            action: (key) => (
              <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });

          navigate(`/users/${response.data.data.id}/edit`);
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ marginBottom: 10 }}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }} className={"smallForms"}>
              <Stack spacing={3}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField
                    fullWidth
                    label='First Name'
                    {...getFieldProps("first_name")}
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                  />

                  <TextField
                    fullWidth
                    label='Last Name'
                    {...getFieldProps("last_name")}
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Stack>

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField
                    fullWidth
                    label='Email/Username'
                    {...getFieldProps("username")}
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                  />

                  {!changePassword ? (
                    <Button
                      variant='contained'
                      startIcon={<RiLockPasswordFill />}
                      sx={{ minWidth: "250px" }}
                      onClick={() => setChangePassword(true)}
                    >
                      Change Password
                    </Button>
                  ) : (
                    <>
                      <TextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        label='Password'
                        {...getFieldProps("password")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                edge='end'
                                onClick={() => setShowPassword((prev) => !prev)}
                              >
                                <Icon
                                  icon={showPassword ? eyeFill : eyeOffFill}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />

                      <TextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        label='Confirm Password'
                        {...getFieldProps("password2")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                edge='end'
                                onClick={() => setShowPassword((prev) => !prev)}
                              >
                                <Icon
                                  icon={showPassword ? eyeFill : eyeOffFill}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(touched.password2 && errors.password2)}
                        helperText={touched.password2 && errors.password2}
                      />
                    </>
                  )}
                </Stack>

                <Box
                  sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    loading={isSubmitting}
                  >
                    {"Save Changes"}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
